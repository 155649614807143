.div-link-dashboard {
    border: 0.05em solid #d0d0d0;
    padding: 1em;
    background: $smoke-white;
    border-radius: 0.35em;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.95em;
}

.div-link-dashboard .container-icon-link-dashboard {
    width: 4em;
    height: 4em;
    border-radius: 0.35em;
    background: $dark-gray;
    display: block;
    margin-left: auto;
    margin-right: auto; 
    margin-bottom: 1em;
}

.div-link-dashboard .container-icon-link-dashboard .icon-link-dashboard {
    width: 2em;
    height: 2em;
    margin-top: 1em;
}

.div-link-dashboard:hover {
    background: $pale-gray;
}

.link-dashboard {
    color: $dark-gray;
}

.dashboard .link-disabled {
    cursor: not-allowed;
    text-decoration: none;
    color: $pale-gray !important;
    pointer-events: none;   
}

.dashboard .link-disabled .container-icon-link-dashboard {
    background: $pale-gray;
}

.dashboard .link-disabled .div-link-dashboard:hover {
    background: $light-gray;
}

@media (min-width: 768px) {
    .main-container {
        max-width: 83% !important;
    }
}

@media (max-width: 768px) {
    // .navbar-brand {
    //     width: 4.5em;
    // }

    .userdata {
        padding-right: 0;
    }

    .div-link-dashboard {
        margin-bottom: 1em;
    }
}

@media (max-width: 465px) {
    .h1-header {
        font-size: 1.5em;
    }
}
#alert .alert {
  padding: 15px;
  top: 78px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100%; }
  #alert .alert .title {
    font-size: 17px;
    font-weight: 500; }
  #alert .alert .messages {
    font-size: 13px; }

#alert .alert-shown {
  opacity: 1;
  z-index: 1300;
  transition: all 250ms linear; }

#alert .alert-hidden {
  opacity: 0;
  z-index: -1;
  transition: all 500ms linear; }

#api-not-available {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  opacity: 0.7;
  z-index: 10001; }
  #api-not-available .text {
    color: red;
    font-size: 48px;
    padding: 20px;
    text-align: center; }
  @media only screen and (max-width: 768px) {
    #api-not-available .text {
      font-size: 40px; } }
  @media only screen and (max-width: 576px) {
    #api-not-available .text {
      font-size: 32px; } }

#drug-search, .drug-search-dumb {
  position: relative; }
  #drug-search > input, .drug-search-dumb > input {
    margin-bottom: 20px; }
  #drug-search .search-list, .drug-search-dumb .search-list {
    margin-top: -20px; }
    #drug-search .search-list ul, .drug-search-dumb .search-list ul {
      position: absolute;
      width: 100%;
      z-index: 1;
      padding: 0px;
      max-height: 320px;
      overflow-y: auto;
      border: 1px solid darkgray;
      border-top: none;
      border-radius: 0px 0px 5px 5px; }
      #drug-search .search-list ul li:hover, .drug-search-dumb .search-list ul li:hover {
        background: #dfdfdf; }
      #drug-search .search-list ul li, .drug-search-dumb .search-list ul li {
        width: 100%;
        list-style-type: none;
        padding: 0.75em 1em;
        border: 0.001em solid #c0c0c0;
        background: #f5f5f5;
        cursor: pointer; }
        #drug-search .search-list ul li .medication, .drug-search-dumb .search-list ul li .medication {
          font-weight: bold;
          display: block; }
        #drug-search .search-list ul li .administration, .drug-search-dumb .search-list ul li .administration {
          display: block; }
        #drug-search .search-list ul li .ingredients, .drug-search-dumb .search-list ul li .ingredients {
          font-size: 0.8em; }
      #drug-search .search-list ul .cross-out, .drug-search-dumb .search-list ul .cross-out {
        color: red;
        text-decoration: line-through;
        cursor: not-allowed; }
  #drug-search .multiple-drugs .select-drug, .drug-search-dumb .multiple-drugs .select-drug {
    font-size: 1.25em;
    font-weight: 500; }
  #drug-search .multiple-drugs > div, .drug-search-dumb .multiple-drugs > div {
    margin-bottom: 15px; }
  #drug-search .multiple-drugs ul, .drug-search-dumb .multiple-drugs ul {
    padding: 0px;
    width: 500px; }
    #drug-search .multiple-drugs ul li, .drug-search-dumb .multiple-drugs ul li {
      padding: 5px 10px;
      margin: 10px;
      border-radius: .25rem;
      list-style-type: none;
      border: 0.001em solid #c0c0c0;
      background: #f5f5f5;
      cursor: pointer; }
      #drug-search .multiple-drugs ul li .ingredients-medication, .drug-search-dumb .multiple-drugs ul li .ingredients-medication {
        display: block; }
  #drug-search__title, .drug-search-dumb__title {
    font-size: 12px;
    color: #17a2b8;
    font-weight: 600;
    padding: 4px 0; }
  #drug-search__input, .drug-search-dumb__input {
    height: 30px;
    padding: 4px 8px;
    font-size: 12px; }
  #drug-search__drug-details, .drug-search-dumb__drug-details {
    font-size: 12px; }

.div-link-dashboard {
  border: 0.05em solid #d0d0d0;
  padding: 1em;
  background: #f5f5f5;
  border-radius: 0.35em;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.95em; }

.div-link-dashboard .container-icon-link-dashboard {
  width: 4em;
  height: 4em;
  border-radius: 0.35em;
  background: #343a40;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em; }

.div-link-dashboard .container-icon-link-dashboard .icon-link-dashboard {
  width: 2em;
  height: 2em;
  margin-top: 1em; }

.div-link-dashboard:hover {
  background: #dfdfdf; }

.link-dashboard {
  color: #343a40; }

.dashboard .link-disabled {
  cursor: not-allowed;
  text-decoration: none;
  color: #dfdfdf !important;
  pointer-events: none; }

.dashboard .link-disabled .container-icon-link-dashboard {
  background: #dfdfdf; }

.dashboard .link-disabled .div-link-dashboard:hover {
  background: #f2f2f2; }

@media (min-width: 768px) {
  .main-container {
    max-width: 83% !important; } }

@media (max-width: 768px) {
  .userdata {
    padding-right: 0; }
  .div-link-dashboard {
    margin-bottom: 1em; } }

@media (max-width: 465px) {
  .h1-header {
    font-size: 1.5em; } }

:root {
  --jumbotron-padding-y: 3rem; }

.jumbotron {
  padding-top: var(--jumbotron-padding-y);
  padding-bottom: var(--jumbotron-padding-y);
  margin-bottom: 0;
  background-color: white; }

@media (min-width: 768px) {
  .jumbotron {
    padding-top: calc(var(--jumbotron-padding-y) * 2);
    padding-bottom: calc(var(--jumbotron-padding-y) * 2); }
  .jumbotron p:last-child {
    margin-top: 30px; } }

.jumbotron p:last-child {
  margin-bottom: 0; }

.jumbotron-heading {
  font-weight: 300; }

.btn-home {
  padding: 0.35em 0.75em;
  margin: 10px; }

.loading {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  opacity: 0.7;
  z-index: 10000; }
  .loading .text {
    position: absolute;
    top: 58%;
    color: #36d7b7;
    font-size: 18px; }

/*
white overlay
*/
.loading-whiteoverlay {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 10000; }
  .loading-whiteoverlay .text {
    position: absolute;
    top: 58%;
    color: #36d7b7;
    font-size: 18px; }

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
  text-align: center; }

.form-signin h1 {
  margin: 20px !important; }

.form-signin .btn {
  margin: 1.5em 0 !important; }

.form-signin .checkbox {
  font-weight: 400; }

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px; }

.form-signin .form-control:focus {
  z-index: 2; }

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.form-signin input[type="password"] {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.link-recovery {
  display: block;
  margin-top: 1em; }

.medication {
  display: flex;
  margin-bottom: 10px; }
  .medication .badge-medication {
    display: flex;
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    background-color: #f2f2f2;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: auto;
    box-sizing: border-box;
    list-style-type: none;
    background-repeat: no-repeat;
    background-size: 0.9em;
    background-position: 95% 50%; }
    .medication .badge-medication > .left-panel .drug-title {
      display: flex;
      justify-content: space-between; }
      .medication .badge-medication > .left-panel .drug-title .drug-name {
        font-size: 15px;
        font-weight: 700; }
      .medication .badge-medication > .left-panel .drug-title .drug-description {
        font-size: 12px;
        margin-left: 4px; }
      .medication .badge-medication > .left-panel .drug-title .edit {
        margin-left: 20px; }
    .medication .badge-medication > .left-panel .status-badge {
      font-size: 15px;
      margin-left: 10px;
      padding: 0px; }
      .medication .badge-medication > .left-panel .status-badge .badge {
        padding: 5px 10px;
        font-weight: 500; }
    .medication .badge-medication > .left-panel .ingredients {
      font-size: 14px;
      display: block; }
    .medication .badge-medication > .left-panel #drug-usage {
      font-size: 12px; }
      .medication .badge-medication > .left-panel #drug-usage select, .medication .badge-medication > .left-panel #drug-usage input {
        height: 30px;
        padding: 4px 8px;
        font-size: 12px; }
      .medication .badge-medication > .left-panel #drug-usage .title, .medication .badge-medication > .left-panel #drug-usage label {
        padding: 4px 0px !important;
        font-size: 12px; }
      .medication .badge-medication > .left-panel #drug-usage .custom-checkbox {
        margin-top: 8px !important; }
    .medication .badge-medication > .right-panel {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      padding-left: 10px; }
      .medication .badge-medication > .right-panel .remove {
        cursor: pointer;
        padding-left: 10px; }
      .medication .badge-medication > .right-panel .action {
        display: flex;
        flex-direction: column;
        align-items: flex-end; }
        .medication .badge-medication > .right-panel .action .fa-undo-alt {
          font-size: 14px;
          padding-top: 3px; }
  .medication .print.badge-medication {
    background-color: white;
    border: none; }

#nav-bar-dashboard {
  /*
    * Sidebar
    */ }
  #nav-bar-dashboard .navbar {
    display: flex;
    max-width: 1024px;
    margin: 0 auto; }
    #nav-bar-dashboard .navbar .navbar-brand {
      position: fixed;
      top: 14px;
      padding: .5rem 0;
      font-size: 1.10rem; }
      #nav-bar-dashboard .navbar .navbar-brand span {
        color: #212529;
        font-weight: 800;
        margin-left: 10px; }
    #nav-bar-dashboard .navbar .btn-nav {
      font-weight: bold;
      font-size: 16px;
      color: #444;
      text-decoration: none;
      width: max-content; }
      #nav-bar-dashboard .navbar .btn-nav :hover {
        border-bottom: 2px solid rgba(68, 127, 255, 0.1); }
      #nav-bar-dashboard .navbar .btn-nav span {
        padding: 5px 1px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1); }
    #nav-bar-dashboard .navbar .form-control {
      padding: .75rem 1rem;
      border-width: 0;
      border-radius: 0; }
    #nav-bar-dashboard .navbar .user-data {
      color: #808080;
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 8px; }
      #nav-bar-dashboard .navbar .user-data div {
        cursor: pointer; }
    #nav-bar-dashboard .navbar .user-data:hover {
      text-decoration: none; }
    #nav-bar-dashboard .navbar .message-icon {
      background: #DFDFDF;
      margin-left: auto !important;
      margin-right: 20px !important; }
    #nav-bar-dashboard .navbar .version {
      padding: 20px;
      color: #343a40; }
  #nav-bar-dashboard .navbar-fixed-top {
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030; }
  #nav-bar-dashboard .logged-in {
    max-width: 100%;
    margin: 0 0 0 0; }
  #nav-bar-dashboard .sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto;
    /* Scrollable contents if viewport is shorter than content. */ }
  @supports (position: -webkit-sticky) or (position: sticky) {
    #nav-bar-dashboard .sidebar-sticky {
      position: -webkit-sticky;
      position: sticky; } }
  #nav-bar-dashboard .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    /* Behind the navbar */
    padding: 80px 0px;
    /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1); }
    #nav-bar-dashboard .sidebar .nav-link {
      font-weight: 500;
      color: #343a40;
      display: flex;
      align-items: center;
      padding: 10px 16px; }
    #nav-bar-dashboard .sidebar .nav-link.active {
      color: blue; }
    #nav-bar-dashboard .sidebar .message-count {
      padding-left: 10px; }
      #nav-bar-dashboard .sidebar .message-count span {
        padding: 3px 9px;
        font-size: 12px;
        background-color: lightgray; }
  #nav-bar-dashboard .collapse-menu .nav-link {
    font-size: 16px;
    color: black;
    display: flex; }
  #nav-bar-dashboard .sidebar-heading {
    font-size: 20px;
    font-weight: 500; }
  #nav-bar-dashboard .link-disabled {
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none; }
  #nav-bar-dashboard .menu-text {
    padding-left: 10px;
    font-size: 14px; }
  #nav-bar-dashboard .menu-icon {
    text-align: center;
    width: 25px; }

@media only screen and (max-width: 768px) {
  #nav-bar-dashboard .navbar .btn-nav {
    margin: 0 !important; }
  #nav-bar-dashboard .navbar .message-icon {
    display: none;
    margin-left: unset !important;
    margin-right: unset !important; }
  #nav-bar-dashboard .navbar .navbar-brand {
    position: unset;
    top: unset; } }

.nav-bar-dashboard-logout nav {
  position: fixed;
  background: #fff;
  transition: none;
  min-height: 50px;
  flex-wrap: nowrap;
  z-index: 1030;
  left: 0;
  right: 0;
  top: 0;
  padding: 0; }
  .nav-bar-dashboard-logout nav .navbar-brand {
    margin-left: 32px; }
  .nav-bar-dashboard-logout nav .navbar-caption-wrap {
    margin: .3rem 0;
    display: flex;
    align-items: center;
    word-break: break-word;
    min-width: 7rem; }
  .nav-bar-dashboard-logout nav .menu-bottom {
    margin: auto;
    width: 100%;
    display: flex;
    padding: 0 2rem;
    position: relative;
    align-items: center;
    justify-content: space-between; }
    .nav-bar-dashboard-logout nav .menu-bottom ul {
      flex-wrap: wrap; }
  @media (max-width: 576px) {
    .nav-bar-dashboard-logout nav .menu-bottom {
      flex-direction: column; } }

.nav-bar-dashboard-logout__menu-logo a {
  font-size: 3.4375rem;
  font-weight: 700;
  text-decoration: none; }
  .nav-bar-dashboard-logout__menu-logo a:hover {
    color: #4f87fb !important; }

@media (max-width: 576px) {
  .nav-bar-dashboard-logout__menu-logo {
    display: none; } }

.nav-bar-dashboard-logout .navbar-collapse ul {
  text-align: center; }

@media (max-width: 991px) {
  .nav-bar-dashboard-logout .navbar-collapse {
    padding-bottom: 1rem; } }

.nav-bar-dashboard-logout__menu {
  width: auto;
  display: flex !important;
  justify-content: flex-end; }
  .nav-bar-dashboard-logout__menu ul {
    display: flex;
    flex-direction: row; }
  .nav-bar-dashboard-logout__menu__item a {
    font-size: 1.125rem;
    margin: 0 1.3rem !important;
    font-weight: 400;
    padding: 0.5rem 0 !important;
    color: #232323; }
    .nav-bar-dashboard-logout__menu__item a:hover {
      color: #4f87fb !important; }

.nav-bar-dashboard-logout__link--active {
  color: #4f87fb !important;
  font-weight: bold !important; }

.nav-bar-dashboard-logout .navbar-toggler {
  outline: none; }

@media (min-width: 992px) {
  .nav-bar-dashboard-logout .navbar-toggleable-sm .navbar-collapse {
    display: flex !important;
    justify-content: flex-end; }
    .nav-bar-dashboard-logout .navbar-toggleable-sm .navbar-collapse ul {
      display: flex;
      flex-direction: row; }
  .nav-bar-dashboard-logout .navbar-toggler {
    display: none; } }

@media (max-width: 780px) {
  .nav-bar-dashboard-logout .nav-hide {
    display: none; } }

.person-icon {
  display: inline-block; }

#pharmacy-select {
  position: relative; }
  #pharmacy-select ul {
    padding: 0px;
    margin: 0px;
    margin-bottom: 50px;
    max-height: 100px;
    width: 100%;
    overflow-y: auto;
    border: 1px solid darkgray;
    border-top: none;
    border-radius: 0px 0px 5px 5px;
    position: absolute;
    z-index: 1; }
    #pharmacy-select ul li:hover {
      background: #dfdfdf; }
    #pharmacy-select ul li {
      width: 100%;
      list-style-type: none;
      padding: 5px 10px;
      border: 0.001em solid #c0c0c0;
      background: #f5f5f5;
      cursor: pointer; }
  #pharmacy-select .item .badge {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    max-width: 700px;
    white-space: normal;
    font-weight: 400;
    text-align: left;
    border-radius: 5px;
    background-color: #f2f2f2;
    margin: 2px;
    cursor: pointer; }
    #pharmacy-select .item .badge .close {
      padding-left: 10px;
      padding-bottom: 5px; }
    #pharmacy-select .item .badge button {
      width: 145px;
      margin-left: 10px; }

.badge-item {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  max-width: 300px;
  white-space: normal;
  font-weight: 400;
  text-align: left;
  border-radius: 5px;
  background-color: #f2f2f2;
  margin: 2px;
  cursor: pointer; }
  .badge-item .close {
    padding-left: 10px;
    padding-bottom: 5px; }

#custom-image img {
  width: 150px; }

#custom-image .fa.fa-edit {
  position: absolute;
  left: 148px; }

#custom-image .pointer {
  cursor: pointer; }

#image-dialog img {
  width: 1000px; }
  @media only screen and (max-width: 1200px) {
    #image-dialog img {
      width: 900px; } }
  @media only screen and (max-width: 992px) {
    #image-dialog img {
      width: 750px; } }
  @media only screen and (max-width: 768px) {
    #image-dialog img {
      width: 600px; } }

#image-dialog .zoom-in-container {
  width: 50%; }

#image-dialog .buttons {
  text-align: right; }

.status-badge {
  padding: 3px 0px; }
  .status-badge .extra-info {
    font-size: 12px;
    padding-left: 5px; }
    .status-badge .extra-info span {
      font-weight: 500;
      padding: 0px 5px;
      color: #606060; }

.format-phone-number {
  align-items: center;
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */ }
  .format-phone-number .format-bracket {
    font-size: 25px;
    margin: 0 1px; }
  .format-phone-number .dash-line {
    font-size: 25px;
    margin: 0 1px; }
  .format-phone-number input::-webkit-outer-spin-button,
  .format-phone-number input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .format-phone-number input[type=number] {
    -moz-appearance: textfield; }

#footer .empty-container {
  display: block;
  padding: 20px;
  height: 60px;
  width: 100%; }

#footer .container {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #f5f5f5;
  z-index: 100;
  text-align: center; }
  #footer .container a {
    color: #343a40;
    padding: 0px 20px;
    font-weight: 500; }
  @media only screen and (max-width: 576px) {
    #footer .container {
      padding-right: 0;
      padding-left: 0; }
      #footer .container a {
        padding: 0 5px; } }

#footer-logged-out {
  border-top: 1px solid #eeeeee; }
  #footer-logged-out .container {
    font-size: 14px;
    padding: 60px 50px;
    max-width: 1024px;
    margin: 0 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row;
    flex-flow: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between; }

#audit-search .select__menu {
  margin-top: 1px; }

.manage-tele-page .item {
  margin-bottom: 20px; }

.manage-tele-page .created-at {
  color: red;
  margin-right: 30px; }

#manage-data-page .title {
  margin-bottom: 10px;
  color: #606060;
  font-weight: 500;
  font-size: 16px; }

#manage-data-page .custom-select {
  width: auto; }

#manage-data-page .import-data, #manage-data-page .display-data {
  display: flex;
  align-items: center;
  margin-bottom: 15px; }
  #manage-data-page .import-data select, #manage-data-page .import-data input, #manage-data-page .display-data select, #manage-data-page .display-data input {
    margin-right: 20px; }

#manage-data-page .display-data {
  align-items: flex-start; }
  #manage-data-page .display-data .display-json {
    margin: 0px 20px; }
    #manage-data-page .display-data .display-json header {
      font-size: 16px;
      font-weight: 500;
      margin: 5px 0px; }
    #manage-data-page .display-data .display-json .json {
      background-color: #f2f2f2;
      padding: 10px;
      font-size: 13px; }

#edit-medical-info-page h1 {
  display: flex;
  align-items: center; }
  #edit-medical-info-page h1 .person {
    color: gray;
    font-size: 26px; }

.medicalinfo .medicalinfo-container__past-text {
  font-size: 15px;
  font-weight: bolder;
  color: #606060; }

.medicalinfo .medicalinfo-container__past ul {
  list-style: none;
  padding-left: 0;
  max-height: 200px;
  overflow-y: scroll; }

.medicalinfo .medicalinfo-container__past li {
  margin-bottom: 10px; }

.medicalinfo .medicalinfo-container {
  width: 100%;
  flex: 1; }
  .medicalinfo .medicalinfo-container ul {
    padding-left: 1.2em; }
  .medicalinfo .medicalinfo-container .li-medication {
    margin-bottom: 1.5em; }
  .medicalinfo .medicalinfo-container .info {
    font-size: 0.8em;
    color: #606060; }
  .medicalinfo .medicalinfo-container .row .margin-bottom {
    margin-bottom: 2em; }
  .medicalinfo .medicalinfo-container hr {
    margin-top: 5px;
    margin-bottom: 15px; }
  .medicalinfo .medicalinfo-container .medications-container {
    display: flex;
    flex-direction: column; }
    .medicalinfo .medicalinfo-container .medications-container .medications-heading {
      display: flex;
      align-items: center; }
    .medicalinfo .medicalinfo-container .medications-container .medications-title {
      flex: 1; }

#icd-select {
  position: relative; }
  #icd-select input {
    width: 90%; }
  #icd-select ul {
    padding: 0px;
    margin: 0px;
    width: 95%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid darkgray;
    border-top: none;
    border-radius: 0px 0px 5px 5px;
    position: absolute;
    z-index: 1; }
    #icd-select ul li:hover {
      background: #dfdfdf; }
    #icd-select ul li {
      list-style-type: none;
      padding: 5px 10px;
      border: 0.001em solid #c0c0c0;
      background: #f5f5f5;
      cursor: pointer; }
  #icd-select .list {
    display: flex;
    flex-direction: column;
    margin-top: 10px; }
  #icd-select .form-control {
    border: none;
    background-color: inherit; }
    #icd-select .form-control:focus {
      box-shadow: none; }
  #icd-select .search-input {
    border: 1px solid #e8e8e8;
    border-radius: 10px; }

#other-surgery input {
  display: inline-block;
  margin-right: 5px;
  width: 80%; }

#other-surgery button {
  vertical-align: initial; }

#other-surgery .list {
  display: flex;
  flex-direction: column;
  margin-top: 10px; }

#other-allergy {
  position: relative; }
  #other-allergy input {
    display: inline-block;
    margin-right: 5px;
    width: 80%; }
  #other-allergy button {
    vertical-align: initial; }
  #other-allergy ul {
    padding: 0px;
    margin: 0px;
    width: 80%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid darkgray;
    border-top: none;
    border-radius: 0px 0px 5px 5px;
    position: absolute;
    z-index: 1000000; }
    #other-allergy ul li:hover {
      background: #dfdfdf; }
    #other-allergy ul li {
      width: 100%;
      list-style-type: none;
      padding: 5px 10px;
      border: 0.001em solid #c0c0c0;
      background: #f5f5f5;
      cursor: pointer; }
  #other-allergy .list {
    display: flex;
    flex-direction: column;
    margin-top: 10px; }

.add-medication-filter {
  display: flex;
  margin: 0; }
  .add-medication-filter__add-form {
    flex: 1; }
  .add-medication-filter__download {
    display: flex;
    align-items: flex-end; }

#medication-filter-table .created-at {
  width: 20%; }

#medication-filter-table .delete {
  width: 5%; }

.add-medication-bill {
  display: flex;
  margin: 0; }
  .add-medication-bill__add-form {
    flex: 1; }
  .add-medication-bill__download {
    display: flex;
    align-items: flex-end; }

#medication-bill-table .created-at {
  width: 20%; }

#medication-bill-table .delete {
  width: 5%; }

.message-table .header {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.message-table table {
  border-top: 1px solid lightgray; }
  .message-table table .unread {
    background-color: white; }
    .message-table table .unread .inbox,
    .message-table table .unread .subject {
      font-weight: bold; }
    .message-table table .unread .updated-at {
      color: black;
      font-weight: bold; }
  .message-table table tr {
    background-color: rgba(242, 245, 245, 0.8);
    box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122); }
    .message-table table tr td {
      cursor: pointer; }
    .message-table table tr .name {
      width: 20%;
      font-size: 14px; }
    .message-table table tr .replies-count {
      color: gray;
      font-size: 12px;
      margin-left: 5px; }
    .message-table table tr .content {
      width: 70%;
      font-size: 14px; }
      .message-table table tr .content .message {
        color: gray; }
    .message-table table tr .action {
      display: table-cell;
      cursor: default;
      width: 10%; }
      .message-table table tr .action button {
        display: flex;
        padding: 0px; }
    .message-table table tr .updated-at {
      display: table-cell;
      width: 10%;
      color: gray;
      font-size: 12px; }

.message-table .tab-inbox tr:hover {
  box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  z-index: 1; }
  .message-table .tab-inbox tr:hover .action {
    display: table-cell; }
  .message-table .tab-inbox tr:hover .updated-at {
    display: table-cell; }

#message {
  padding: 10px; }
  #message .subject {
    margin-bottom: 20px; }
  #message .title {
    display: flex;
    justify-content: space-between; }
  #message .person {
    font-weight: bold; }
  #message .date {
    color: #5f6368;
    font-size: 12px; }
  #message .content {
    width: 80%; }
  #message .replies {
    padding-left: 10px;
    padding-bottom: 20px; }

.note {
  padding: 0px;
  border-radius: 10px;
  border: 1px solid lightgray;
  background-color: white;
  margin-bottom: 10px;
  margin: 5px; }
  .note .header {
    padding: 3px 5px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .note .header a {
      margin-right: 5px; }
    .note .header .note-id {
      font-size: 10px;
      opacity: 0.1; }
  .note .text {
    font-size: 14px;
    padding: 0px 5px;
    margin-bottom: 5px; }
    .note .text .multiline-text {
      font-size: 13px; }
  .note .footer {
    padding: 3px 10px;
    background-color: #e6e6ff;
    border-radius: 0px 0px 10px 10px;
    color: gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px; }
    .note .footer .created-at {
      font-size: 11px;
      margin-right: 5px; }
    .note .footer .revision a {
      font-size: 13px; }

.note.is-sent {
  background-color: #e6fff5; }

.note.is-sent-to-emr {
  background-color: #fa755a; }

.note.has-reply .footer {
  border-radius: 0px; }

.note.selected {
  border: dashed 5px #17a2b8; }

.note-draft {
  padding: 0px 5px 5px 5px; }
  .note-draft .btn:disabled {
    cursor: not-allowed; }
  .note-draft .add-note, .note-draft .close-note {
    cursor: pointer;
    font-size: 20px;
    padding: 3px;
    margin: 2px; }
  .note-draft .add-note:hover, .note-draft .close-note:hover {
    background-color: lightgrey;
    border-radius: 20px; }

.note-panel {
  background-color: #f2f2f2;
  border-radius: 5px;
  border: 1px solid #ced4da; }
  .note-panel .notes {
    overflow-y: auto;
    max-height: 600px; }
  .note-panel textarea {
    font-size: 13px;
    padding: 5px; }

.note-reply {
  background-color: white;
  border-radius: 0px 0px 10px 10px;
  font-size: 12px; }
  .note-reply .title {
    padding: 2px 10px;
    font-weight: 500;
    color: gray;
    border-bottom: 1px solid darkgray;
    font-size: 13px; }
  .note-reply .reply {
    padding: 5px 10px;
    padding-bottom: 5px;
    color: #ff6666; }
    .note-reply .reply .from {
      display: flex;
      justify-content: space-between; }
      .note-reply .reply .from .person {
        font-weight: 500; }
      .note-reply .reply .from .replied-at {
        color: gray;
        font-size: 11px; }
    .note-reply .reply .message {
      padding: 0px;
      display: flex;
      justify-content: space-between; }

.note-revision-dialog .note-revision {
  background-color: #f2f2f2;
  border-radius: 10px;
  margin: 10px 0px;
  border: 1px solid #606060; }
  .note-revision-dialog .note-revision .text {
    padding: 5px 10px; }
  .note-revision-dialog .note-revision .footer {
    background-color: #e6e6ff;
    border-radius: 0px 0px 10px 10px;
    padding: 5px 10px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center; }

.patient-page h1 {
  display: flex;
  align-items: center; }

.patient-page .patient-name {
  font-size: 24px;
  color: gray; }

.patient-page .content {
  display: flex; }
  .patient-page .content > .left-panel {
    flex-basis: 65%; }
    .patient-page .content > .left-panel .patient-info {
      width: 100%; }
      .patient-page .content > .left-panel .patient-info .patient-name {
        font-weight: 500;
        font-size: 14px; }
      .patient-page .content > .left-panel .patient-info button {
        margin-right: 10px;
        margin-bottom: 10px; }
      .patient-page .content > .left-panel .patient-info hr {
        margin-top: 5px;
        margin-bottom: 15px; }
    .patient-page .content > .left-panel .title {
      font-weight: 600;
      font-size: 20px;
      color: #17a2b8; }
      .patient-page .content > .left-panel .title .btn {
        padding: 2px 6px;
        font-size: 14px; }
  .patient-page .content > .right-panel {
    display: flex;
    flex-direction: column;
    flex-basis: 35%;
    padding-left: 10px; }
    .patient-page .content > .right-panel .status {
      font-size: 24px;
      margin-bottom: 20px; }
      .patient-page .content > .right-panel .status .badge {
        padding: 8px 16px;
        font-weight: 500; }

.patient-page strong {
  color: #606060;
  font-size: 14px; }

#pending-prescription {
  overflow-y: auto;
  max-height: 500px; }
  #pending-prescription .prescription.selected {
    border: dashed 5px #17a2b8; }
  #pending-prescription .prescription {
    display: flex;
    width: 100%;
    padding: 10px;
    margin: 5px 0px;
    border: 1px solid darkgray;
    border-radius: 5px; }
    #pending-prescription .prescription > .left-panel {
      flex-basis: 70%; }
      #pending-prescription .prescription > .left-panel .medication:last-child {
        margin: 0px; }
    #pending-prescription .prescription > .right-panel {
      flex-basis: 30%;
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      #pending-prescription .prescription > .right-panel .created-at {
        color: #343a40;
        margin-bottom: 5px; }
      #pending-prescription .prescription > .right-panel .prescription-id {
        color: white; }

#pending-sleep-assessment {
  width: 100%;
  max-height: 500px;
  overflow-y: auto; }
  #pending-sleep-assessment .sleep-assessment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px; }
    #pending-sleep-assessment .sleep-assessment .id {
      margin-left: 20px;
      color: white; }
    #pending-sleep-assessment .sleep-assessment--selected {
      border: dashed 5px #17a2b8; }

#pending-labtest {
  width: 100%;
  max-height: 500px;
  overflow-y: scroll; }
  #pending-labtest .labtest {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
    border: 1px solid #A9A9A9;
    border-radius: 5px;
    background-color: #F2F2F2; }
    #pending-labtest .labtest--selected {
      border: dashed 5px #17a2b8; }

#sleep-assessment-form {
  margin: auto;
  width: 650px;
  margin-top: 10px;
  border: 1px solid black; }
  #sleep-assessment-form .header {
    padding: 10px 20px; }
    #sleep-assessment-form .header div {
      font-size: 20px;
      font-weight: 500; }
    #sleep-assessment-form .header__comment {
      font-size: 16px;
      font-weight: 400; }
  #sleep-assessment-form .personal-info {
    padding: 10px;
    display: flex;
    border-top: 1px solid black; }
    #sleep-assessment-form .personal-info .field-name {
      display: inline-block;
      width: 120px; }
  #sleep-assessment-form .sleep-info {
    padding: 10px 20px;
    border-top: 1px solid black; }
    #sleep-assessment-form .sleep-info strong {
      font-size: 14px;
      font-weight: 400;
      color: black; }
    #sleep-assessment-form .sleep-info .col-md-12 {
      margin: 5px 0px !important; }
  #sleep-assessment-form .footer {
    padding: 10px 20px;
    border-top: 1px solid black; }
    #sleep-assessment-form .footer .sigCanvasSleepment {
      width: 250px;
      height: 60px;
      border: 1px solid black; }
    #sleep-assessment-form .footer > div {
      display: flex;
      justify-content: space-between; }
      #sleep-assessment-form .footer > div > div > div {
        padding: 5px 0px; }
        #sleep-assessment-form .footer > div > div > div .field-name {
          display: inline-block;
          padding-right: 10px; }
  #sleep-assessment-form .title {
    font-size: 18px;
    margin-bottom: 10px; }

.prescription-page .prescription-id {
  font-size: 24px;
  color: #606060; }

.prescription-page .content {
  display: flex; }
  .prescription-page .content > .left-panel {
    flex-basis: 65%;
    display: flex;
    flex-direction: column; }
    .prescription-page .content > .left-panel .title {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 10px;
      color: #17a2b8; }
    .prescription-page .content > .left-panel .medications {
      flex-basis: 60%;
      padding: 0px 10px; }
    .prescription-page .content > .left-panel .patient-info {
      flex-basis: 40%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 10px; }
      .prescription-page .content > .left-panel .patient-info .patient-name {
        font-weight: 500;
        font-size: 14px; }
      .prescription-page .content > .left-panel .patient-info button {
        margin-right: 10px;
        margin-bottom: 10px; }
  .prescription-page .content > .right-panel {
    display: flex;
    flex-direction: column;
    flex-basis: 35%;
    padding-left: 10px; }
    .prescription-page .content > .right-panel .status {
      font-size: 24px;
      margin-bottom: 20px; }
      .prescription-page .content > .right-panel .status .badge {
        padding: 8px 16px;
        font-weight: 500; }
      .prescription-page .content > .right-panel .status .status-badge .extra-info {
        font-size: 18px; }

.prescription-table {
  margin-top: 20px; }
  .prescription-table table {
    min-width: 700px; }
    .prescription-table table tbody tr .name {
      width: 30%; }
    .prescription-table table tbody tr .created-at {
      width: 15%; }
    .prescription-table table tbody tr .status {
      font-size: 16px; }
      .prescription-table table tbody tr .status .badge {
        padding: 5px 10px;
        font-weight: 500; }
    .prescription-table table tbody tr .invoice {
      width: 10%; }
    .prescription-table table tbody tr:hover {
      background-color: #e6fff5;
      box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
      z-index: 1;
      cursor: pointer; }
  .prescription-table .font-weight-500 {
    font-weight: 500; }

.cover-page {
  margin: auto;
  width: 650px;
  margin-top: 10px;
  border: 1px solid black;
  padding: 25px;
  font-size: 20px;
  color: black;
  line-height: 1.3; }
  .cover-page .bold {
    font-weight: bold; }
  .cover-page .title-container {
    font-weight: bold;
    display: flex;
    align-items: baseline;
    justify-content: space-between; }
    .cover-page .title-container .title__web {
      font-size: 30px; }
    .cover-page .title-container .title__fax {
      font-size: 110px; }
  .cover-page .patient-require {
    line-height: 1;
    font-size: 25px; }
  .cover-page .service-availability__availability {
    text-shadow: 2px 2px #aaaaaa; }
  .cover-page .contact-container {
    border: 1px solid gray;
    padding-top: 15px;
    padding-left: 15px;
    margin-bottom: 25px; }
    .cover-page .contact-container .pharmacy-info__name, .cover-page .contact-container .pharmacy-info__number {
      font-style: italic; }
    .cover-page .contact-container div {
      margin-bottom: 20px; }
    .cover-page .contact-container p {
      margin: 0; }
    .cover-page .contact-container .subject-title {
      font-size: 25px;
      font-weight: bold; }
    .cover-page .contact-container .subject-content {
      font-size: 35px;
      text-decoration: underline; }
    .cover-page .contact-container .subject-text {
      font-size: 25px; }
  .cover-page .note-container {
    border: 1px solid gray;
    margin-top: 25px;
    font-size: 15px;
    padding: 10px; }

#prescription-form {
  margin: auto;
  width: 650px;
  margin-top: 10px;
  border: 1px solid black; }
  #prescription-form .header {
    align-items: center; }
    #prescription-form .header .message {
      padding: 5px 10px;
      font-size: 16px;
      font-weight: 500;
      text-align: center; }
    #prescription-form .header .banner {
      border-top: 1px solid black;
      display: flex; }
      #prescription-form .header .banner .logo {
        border: 1px solid black;
        border-width: 0 1px 0 0; }
        #prescription-form .header .banner .logo img {
          width: 158px;
          height: 111px;
          margin: 3px; }
      #prescription-form .header .banner .clinic {
        padding: 5px 10px; }
        #prescription-form .header .banner .clinic .clinic-owner {
          font-size: 14px;
          font-weight: bold; }
        #prescription-form .header .banner .clinic .clinic-name {
          font-size: 20px;
          font-weight: bold; }
          #prescription-form .header .banner .clinic .clinic-name span {
            font-style: italic; }
        #prescription-form .header .banner .clinic .clinic-address {
          padding-bottom: 10px; }
  #prescription-form .content {
    border-top: 1px solid black; }
    #prescription-form .content .today {
      display: flex;
      justify-content: flex-end;
      padding: 5px 10px;
      font-weight: bold;
      float: right; }
    #prescription-form .content .profile {
      display: flex;
      justify-content: space-between; }
      #prescription-form .content .profile .person {
        padding: 5px 10px;
        width: 100%; }
        #prescription-form .content .profile .person .healthInfo {
          font-weight: bold; }
      #prescription-form .content .profile .allergy {
        padding: 5px 10px;
        width: 350px; }
        #prescription-form .content .profile .allergy ul {
          margin-bottom: 10px;
          padding-left: 1.2em;
          font-size: 13px; }
    #prescription-form .content .medications .medication {
      border-top: 1px solid black;
      margin-bottom: 0px; }
      #prescription-form .content .medications .medication .badge-medication {
        padding: 0 10px; }
    #prescription-form .content .doctorNote {
      padding: 10px;
      margin: 0;
      border-top: 1px solid black; }
      #prescription-form .content .doctorNote label {
        display: block;
        font-size: 15px;
        font-weight: 700; }
      #prescription-form .content .doctorNote textarea {
        width: 100%;
        height: 65px; }
    #prescription-form .content .signature {
      padding: 5px 30px;
      text-align: center;
      border-top: 1px solid black; }
      #prescription-form .content .signature div {
        font-weight: bold;
        font-size: 20px; }
      #prescription-form .content .signature .signature-wrapper {
        display: flex;
        align-items: center; }
      #prescription-form .content .signature .signature-img {
        width: 200px;
        height: 50px;
        padding-left: 20px; }
      #prescription-form .content .signature .sigCanvas {
        border: 1px solid black;
        width: 400px;
        height: 100px; }
      #prescription-form .content .signature .underline {
        border-top: 1px solid black;
        display: inline-block;
        width: 65%; }
      #prescription-form .content .signature .formal-sign {
        font-size: 14px; }
    #prescription-form .content .pharmacy {
      padding: 10px;
      border-top: 1px solid black; }
      #prescription-form .content .pharmacy div {
        text-align: center; }

@media print {
  .no-print {
    display: none !important; }
  .prescription {
    width: 80%; } }

.cover-page-1 {
  margin: auto;
  width: 650px;
  margin-top: 10px;
  border: 1px solid black;
  padding: 25px;
  font-size: 20px;
  color: black; }
  .cover-page-1 p {
    margin-bottom: 0; }
  .cover-page-1 strong {
    font-size: inherit;
    color: black; }
  .cover-page-1 .textContainer {
    font-size: 25px; }
    .cover-page-1 .textContainer .pharmacy-info {
      font-style: italic; }
    .cover-page-1 .textContainer .title {
      margin-bottom: 0; }
      .cover-page-1 .textContainer .title__text {
        text-decoration: underline; }
    .cover-page-1 .textContainer .text {
      margin-bottom: 0; }
      .cover-page-1 .textContainer .text--strong {
        font-size: inherit;
        font-weight: 800; }
    .cover-page-1 .textContainer .webname {
      letter-spacing: 3px;
      font-size: 50px;
      font-weight: bold; }
  .cover-page-1 .row {
    margin-top: 10px; }
    .cover-page-1 .row .website {
      font-size: 50px; }
    .cover-page-1 .row .text-request {
      font-size: 22px;
      line-height: 24px; }
      .cover-page-1 .row .text-request strong {
        font-size: inherit; }
    .cover-page-1 .row .ct {
      border: 1px solid black;
      font-size: 28px;
      font-weight: bold;
      padding: 20px;
      text-align: center;
      margin-top: 25px; }
    .cover-page-1 .row .words-error {
      margin-top: 20px;
      font-size: 20px; }
    .cover-page-1 .row .rx-logo {
      width: 150px;
      height: 120px;
      margin-left: 20%;
      margin-top: 20px; }
    .cover-page-1 .row .text-location {
      position: absolute;
      left: 60px;
      top: 45px;
      font-size: 23px;
      font-weight: bold;
      text-shadow: 1px 1px #000000; }
    .cover-page-1 .row .text-service {
      font-weight: bold;
      padding-left: 20px;
      padding-top: 10px; }
    .cover-page-1 .row .contact {
      margin-top: 20px; }

#invoice-dialog {
  margin: auto;
  width: 650px;
  margin-top: 10px;
  border: 1px solid black; }
  #invoice-dialog .header {
    border-bottom: 1px solid black;
    align-items: center; }
    #invoice-dialog .header .banner {
      display: flex; }
      #invoice-dialog .header .banner .logo {
        padding: 20px 40px;
        font-size: 40px; }
      #invoice-dialog .header .banner .clinic {
        border-left: 1px solid black;
        padding: 5px 10px; }
        #invoice-dialog .header .banner .clinic .clinic-owner {
          font-size: 14px;
          font-weight: bold; }
        #invoice-dialog .header .banner .clinic .clinic-name {
          font-size: 20px;
          font-weight: bold; }
          #invoice-dialog .header .banner .clinic .clinic-name span {
            font-style: italic; }
        #invoice-dialog .header .banner .clinic .clinic-address {
          padding-bottom: 10px; }
  #invoice-dialog .content .title {
    padding: 10px;
    text-align: center; }
    #invoice-dialog .content .title .text {
      font-size: 26px; }
    #invoice-dialog .content .title .prescriptionId {
      font-size: 16px; }
  #invoice-dialog .content .person {
    padding: 5px 10px; }
    #invoice-dialog .content .person .name {
      font-size: 18px;
      font-weight: 500; }
  #invoice-dialog .content .medications {
    border-bottom: 1px solid black; }
    #invoice-dialog .content .medications .medication {
      border-top: 1px solid black;
      margin-bottom: 0px; }
      #invoice-dialog .content .medications .medication .badge-medication {
        padding: 10px; }
  #invoice-dialog .content .price {
    padding: 10px;
    width: 60%; }
    #invoice-dialog .content .price .title {
      padding: 5px 0px;
      font-size: 18px;
      font-weight: 500;
      text-align: left; }
    #invoice-dialog .content .price .charge, #invoice-dialog .content .price .refund {
      display: flex;
      justify-content: space-between; }

.problem-page table .row {
  background-color: rgba(242, 245, 245, 0.8);
  box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122); }

.problem-page table .message {
  width: 55%;
  font-size: 14px; }

.problem-page table .screenshot {
  width: 15%;
  text-align: center; }
  .problem-page table .screenshot img {
    width: 100px !important; }

.problem-page table .person {
  width: 15%;
  text-align: center; }

.problem-page table .created-at {
  width: 15%;
  color: gray;
  font-size: 12px;
  text-align: center; }

#screenshot-icon {
  color: white;
  position: fixed;
  bottom: 40px;
  left: 30px;
  z-index: 10000;
  background-color: #343a40;
  border-radius: 50px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer; }
  #screenshot-icon .fa-camera {
    font-size: 20px;
    color: white; }
  #screenshot-icon .description {
    color: white;
    width: 120px;
    font-size: 11px;
    padding-left: 5px; }
  @media only screen and (max-width: 576px) {
    #screenshot-icon .description {
      display: none;
      width: 75px; } }

@media only screen and (max-width: 576px) {
  #screenshot-icon {
    bottom: 0px;
    left: 0px; } }

#screenshot-icon:hover .fa-camera, #screenshot-icon:hover .description {
  color: #bee5eb; }

.shown {
  opacity: 0.7;
  transition: all 250ms linear; }

.hidden {
  opacity: 0;
  transition: all 250ms linear; }

.screenshot {
  width: 800px; }
  @media only screen and (max-width: 1200px) {
    .screenshot {
      width: 600px; } }
  @media only screen and (max-width: 992px) {
    .screenshot {
      width: 500px; } }
  @media only screen and (max-width: 768px) {
    .screenshot {
      width: 400px; } }

.profile-page .confirm-error-div {
  display: flex;
  align-items: flex-end; }
  .profile-page .confirm-error-div label {
    color: red; }

.profile-page .privatepay-text {
  font-weight: 700;
  font-size: 15px;
  color: #606060; }

.profile label {
  font-weight: 700;
  color: #606060; }

.register .form-control {
  box-shadow: none;
  color: #565656;
  line-height: 1.43;
  min-height: 3.5em;
  padding: 1rem;
  font-size: 1.125rem; }

.register label {
  font-size: 1rem; }

.register .submit-button {
  margin: .4rem 0;
  padding: 10px 15px;
  font-size: 1.125rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.18);
  font-weight: 600; }
  .register .submit-button:hover {
    color: #4f87fb !important;
    background: #fff !important;
    border-color: #fff !important; }

#agreement-dialog .text-info {
  font-size: 16px;
  padding-left: 10px; }

#agreement-dialog .content {
  width: 100%;
  height: 500px; }
  #agreement-dialog .content .title {
    text-align: center;
    font-size: 20px;
    font-weight: 500; }
  #agreement-dialog .content .fee-box {
    font-size: 17px; }
  #agreement-dialog .content .section > .title {
    text-align: left;
    font-size: 16px; }
  #agreement-dialog .content .section > div {
    margin-bottom: 15px; }
  #agreement-dialog .content .section .sub-section {
    padding: 0px 40px; }

#agreement-dialog .action {
  display: flex;
  justify-content: space-between; }
  #agreement-dialog .action .agreement {
    padding-left: 40px; }
  #agreement-dialog .action .agreement.disabled {
    cursor: not-allowed; }
    #agreement-dialog .action .agreement.disabled input, #agreement-dialog .action .agreement.disabled label {
      cursor: not-allowed; }

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem; } }

@media (min-width: 768px) {
  .jumbotron {
    padding-top: calc(var(--jumbotron-padding-y)*2);
    padding-bottom: calc(var(--jumbotron-padding-y)*2); } }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  border-radius: 0.3rem; }

.cid-s9MPQnQRip {
  text-align: center;
  font-size: 1.125rem; }
  .cid-s9MPQnQRip .modal-header {
    border-bottom: none; }
    .cid-s9MPQnQRip .modal-header .modal-title {
      width: 100%;
      font-size: calc(1.30625rem + 0.56875*(100vw - 20rem)/28); }
  .cid-s9MPQnQRip .card-img img {
    width: 100%; }

.home-container {
  /* Tablets (landscape) */ }
  .home-container .stats-info {
    background: #fafafa;
    color: #606060;
    padding: 13px 150px 13px 150px;
    min-height: 200px; }
    .home-container .stats-info .accolade {
      padding: 15px; }
      .home-container .stats-info .accolade .icon-header {
        text-align: center; }
      .home-container .stats-info .accolade .accolade-header {
        font-weight: bold;
        margin-top: 10px; }
      .home-container .stats-info .accolade i {
        font-size: 3em;
        color: #447fff; }
      .home-container .stats-info .accolade p {
        font-weight: 400; }
  .home-container .cid-s8e6SevhPb {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: white; }
    .home-container .cid-s8e6SevhPb .contents {
      text-align: center;
      margin-bottom: 54px; }
      .home-container .cid-s8e6SevhPb .contents .mbr-subtitle {
        color: #4f87fb;
        margin-bottom: 10px;
        font-weight: 800;
        font-size: 13px;
        letter-spacing: 2px;
        line-height: 1.2;
        font-family: Muli,sans-serif; }
      .home-container .cid-s8e6SevhPb .contents .mbr-section-title {
        margin-bottom: 20px;
        line-height: 48px;
        letter-spacing: -.5px;
        font-weight: 700;
        color: #130947;
        font-size: 1.6rem;
        font-family: Muli,sans-serif; }
      .home-container .cid-s8e6SevhPb .contents .mbr-section-text {
        line-height: 28px;
        margin-top: 20px;
        color: #6d7a8c;
        letter-spacing: 0;
        font-size: 1.125rem;
        font-family: Muli,sans-serif;
        font-weight: 300; }
    .home-container .cid-s8e6SevhPb .btn[class*=-outline] {
      border: none;
      padding: 0;
      margin: 0 !important;
      box-shadow: none !important; }
      .home-container .cid-s8e6SevhPb .btn[class*=-outline]:hover {
        color: #4f87fb !important; }
    .home-container .cid-s8e6SevhPb .row {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px; }
    .home-container .cid-s8e6SevhPb .justify-content-center {
      justify-content: center !important; }
    .home-container .cid-s8e6SevhPb .p-3 {
      padding: 1rem !important; }
    .home-container .cid-s8e6SevhPb .card {
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: border-box;
      border-radius: .25rem;
      border-style: none; }
    .home-container .cid-s8e6SevhPb .card-wrapper {
      height: 100%;
      flex: 1 1; }
      .home-container .cid-s8e6SevhPb .card-wrapper .card-img {
        position: relative;
        text-align: center;
        flex-shrink: 0;
        border-bottom-right-radius: calc(.25rem - 1px);
        border-bottom-left-radius: calc(.25rem - 1px);
        border-top-left-radius: calc(.25rem - 1px);
        border-top-right-radius: calc(.25rem - 1px);
        width: 100%; }
        .home-container .cid-s8e6SevhPb .card-wrapper .card-img img {
          transition: all .3s;
          border-radius: 8px;
          vertical-align: middle;
          border-style: none;
          width: 100%; }
        .home-container .cid-s8e6SevhPb .card-wrapper .card-img .meta-category {
          background-color: #4f87fb;
          display: inline-block;
          padding: 6px 25px;
          color: #fff;
          position: absolute;
          bottom: -15px;
          right: 35px;
          text-transform: uppercase;
          font-size: 12px;
          letter-spacing: .5px;
          z-index: 9;
          border-radius: 15px; }
      .home-container .cid-s8e6SevhPb .card-wrapper .mbr-link-btn .btn {
        font-weight: 600 !important;
        line-height: 24px;
        letter-spacing: 1px;
        font-size: 12px; }
      .home-container .cid-s8e6SevhPb .card-wrapper .mbr-link-btn .btn-white-outline {
        background: none;
        color: #fff; }
      .home-container .cid-s8e6SevhPb .card-wrapper .mbr-link-btn .btn-md {
        border-radius: 6px; }
      .home-container .cid-s8e6SevhPb .card-wrapper .card-box {
        padding-top: 2rem; }
        .home-container .cid-s8e6SevhPb .card-wrapper .card-box .card-title {
          color: #28303f;
          transition: all .3s;
          font-weight: 700;
          margin-bottom: 10px;
          font-size: 22px;
          line-height: 28px;
          letter-spacing: -.5px;
          font-family: Muli,sans-serif; }
          .home-container .cid-s8e6SevhPb .card-wrapper .card-box .card-title:hover {
            color: #4f87fb;
            transition: all .3s;
            text-decoration: none; }
        .home-container .cid-s8e6SevhPb .card-wrapper .card-box .mbr-text {
          margin-bottom: 25px;
          color: #6d7a8c;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.5; }
      @media (max-width: 768px) {
        .home-container .cid-s8e6SevhPb .card-wrapper .card-box {
          text-align: center; } }
    .home-container .cid-s8e6SevhPb .btn[class*=-outline] {
      border: none;
      padding: 0;
      margin: 0 !important;
      box-shadow: none !important; }
    .home-container .cid-s8e6SevhPb .align-left {
      text-align: left; }
  .home-container .cid-s8mZZpGuuw {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #f3f3f3; }
    .home-container .cid-s8mZZpGuuw .main-title {
      font-size: 1.6rem; }
    .home-container .cid-s8mZZpGuuw .mbr-bold {
      font-weight: 700; }
    .home-container .cid-s8mZZpGuuw .align-center {
      text-align: center; }
    .home-container .cid-s8mZZpGuuw .mbr-black {
      color: #000; }
    .home-container .cid-s8mZZpGuuw .main-subtitle {
      color: #999;
      font-size: 1.125rem;
      font-family: Muli,sans-serif; }
    .home-container .cid-s8mZZpGuuw .mbr-regular {
      font-weight: 400; }
    .home-container .cid-s8mZZpGuuw .card {
      margin-bottom: 2rem;
      overflow: visible;
      background-color: initial;
      border: none;
      display: -webkit-flex; }
    .home-container .cid-s8mZZpGuuw .card-wrapper {
      background-color: #fff;
      padding: 1rem;
      transition: all .3s;
      flex: 1 1; }
      .home-container .cid-s8mZZpGuuw .card-wrapper:hover {
        box-shadow: 0 30px 30px -20px rgba(50, 50, 50, 0.3); }
    .home-container .cid-s8mZZpGuuw .card-box {
      text-align: center;
      border: 1px solid transparent;
      padding: 2rem 1rem;
      transition: all .3s; }
      .home-container .cid-s8mZZpGuuw .card-box a:hover {
        text-decoration: none; }
      .home-container .cid-s8mZZpGuuw .card-box .card-text {
        color: #999; }
    @media (max-width: 767px) {
      .home-container .cid-s8mZZpGuuw .card-box {
        border: 1px solid #f3f3f3; } }
  .home-container .cid-s8HgOFvBHp {
    padding-top: 90px;
    padding-bottom: 90px;
    background-color: #fff; }
    .home-container .cid-s8HgOFvBHp .container {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto; }
    .home-container .cid-s8HgOFvBHp .align-center {
      text-align: center; }
    .home-container .cid-s8HgOFvBHp .contents {
      margin-bottom: 70px; }
      .home-container .cid-s8HgOFvBHp .contents .mbr-subtitle {
        letter-spacing: 2px;
        line-height: 24px;
        font-size: 13px;
        color: #4f87fb;
        margin-bottom: 10px;
        font-weight: 800;
        font-family: Muli,sans-serif; }
      .home-container .cid-s8HgOFvBHp .contents .mbr-section-title {
        margin-bottom: 20px;
        color: #130947;
        font-weight: 700;
        text-align: center;
        font-size: 1.6rem; }
      .home-container .cid-s8HgOFvBHp .contents .mbr-section-text {
        line-height: 28px;
        letter-spacing: 0;
        margin-top: 20px;
        color: #6d7a8c;
        font-weight: 400;
        font-size: 1.125rem; }
    .home-container .cid-s8HgOFvBHp .card {
      margin-bottom: 2rem;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: initial;
      border: none;
      border-radius: .25rem; }
      .home-container .cid-s8HgOFvBHp .card .card-block {
        font-size: 16px;
        line-height: 26px;
        font-weight: 400;
        letter-spacing: .03em;
        position: relative;
        background: #4f87fb;
        color: #232323;
        box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
        padding: 1.9rem;
        margin-bottom: 35px;
        border-radius: 4px; }
        .home-container .cid-s8HgOFvBHp .card .card-block p {
          color: #fff;
          text-align: center;
          margin-top: 0;
          margin-bottom: 1rem; }
          .home-container .cid-s8HgOFvBHp .card .card-block p a:hover {
            text-decoration: none;
            color: #b3b3b3 !important; }
        .home-container .cid-s8HgOFvBHp .card .card-block :before {
          content: "";
          position: absolute;
          bottom: -15px;
          left: 50%;
          margin-left: -14px;
          width: 0;
          height: 0;
          border-left: 15px solid transparent;
          border-top: 15px solid #4f87fb;
          border-right: 15px solid transparent; }
      .home-container .cid-s8HgOFvBHp .card .card-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top: none;
        background: none;
        padding: 0 1.25rem 1.25rem; }
        .home-container .cid-s8HgOFvBHp .card .card-footer :last-child {
          border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px); }
        .home-container .cid-s8HgOFvBHp .card .card-footer .name-wrap .mbr-text a:hover {
          text-decoration: none; }
  .home-container .cid-rSbev8cAt7 {
    padding-top: 0;
    padding-bottom: 0;
    background-color: #4f87fb; }
    .home-container .cid-rSbev8cAt7 .row {
      position: relative;
      box-sizing: border-box;
      padding-top: 100px;
      padding-bottom: 90px;
      transition: opacity 0.5s ease 0s;
      overflow: hidden;
      background-color: #4f87fb !important;
      background-position: 50% center !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      display: -webkit-flex;
      flex-wrap: wrap; }
    .home-container .cid-rSbev8cAt7 .row:before {
      background-color: #4f87fb;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      content: "";
      display: block;
      z-index: 0;
      opacity: .8; }
    .home-container .cid-rSbev8cAt7 .stats-item {
      display: flex;
      justify-content: center; }
      .home-container .cid-rSbev8cAt7 .stats-item :first-child {
        border-radius: 10px 0 0 10px; }
        @media (min-width: 768px) {
          .home-container .cid-rSbev8cAt7 .stats-item :first-child:after {
            content: "";
            height: 100%;
            width: 1px;
            background-color: rgba(255, 255, 255, 0.22);
            position: absolute;
            top: 0;
            right: 0; } }
    @media (max-width: 768px) {
      .home-container .cid-rSbev8cAt7 .stats-item {
        padding: 30px 25px; } }
    .home-container .cid-rSbev8cAt7 .counter {
      line-height: 46px;
      letter-spacing: -.5px;
      text-align: left;
      color: #fff;
      font-weight: 900;
      font-size: 1.6rem; }
    .home-container .cid-rSbev8cAt7 .mbr-card-title {
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 1px;
      text-align: center;
      color: #fff;
      display: block;
      font-weight: 300; }
  .home-container .justify-content-center {
    justify-content: center !important; }
  .home-container .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
  .home-container .hero {
    max-width: 1024px;
    margin: 0 auto; }
    .home-container .hero .learn-more-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .home-container .hero .learn-more-container h2 {
        flex: 1;
        font-size: 2em;
        text-align: center;
        margin-bottom: 20px; }
      .home-container .hero .learn-more-container .text {
        flex: 3;
        text-align: center;
        margin-bottom: 40px; }
      .home-container .hero .learn-more-container .btn {
        padding: 20px 0;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
        border-radius: 30px;
        background-color: #447fff;
        letter-spacing: 1px;
        text-align: center;
        font-size: 16px;
        margin-right: 0;
        width: 70%; }
    .home-container .hero .location {
      font-weight: 500; }
    .home-container .hero .lead {
      width: 100%; }
    .home-container .hero .hero-header {
      font-weight: 800;
      font-size: 56px;
      max-width: 600px; }
    .home-container .hero .with-logo {
      background-image: url("../../assets/img/stethoscope.jpg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% auto; }
    .home-container .hero .logo-mobile-only {
      display: none; }
  .home-container .info {
    max-width: 1024px;
    margin: 0 auto; }
    .home-container .info .lead {
      width: 100%; }
    .home-container .info .hero-header {
      font-weight: 800;
      font-size: 56px;
      max-width: 600px; }
    .home-container .info .btn {
      padding: 20px;
      width: 40%;
      color: #fff;
      font-weight: bold;
      text-transform: uppercase;
      border-radius: 30px;
      background-color: #447fff;
      letter-spacing: 1px;
      text-align: center;
      font-size: 16px;
      margin-right: 0; }
    .home-container .info .with-logo {
      background-image: url("../../assets/img/heartbeat.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% auto; }
  .home-container .steps {
    background-color: #77a2ff;
    color: #eee;
    text-align: center;
    text-align: -webkit-center;
    margin: 0 auto; }
    .home-container .steps .lead {
      width: 100%; }
    .home-container .steps .hero-header {
      font-weight: 800;
      font-size: 56px;
      max-width: 600px; }
    .home-container .steps .btn {
      padding: 20px;
      width: 40%;
      color: #fff;
      font-weight: bold;
      text-transform: uppercase;
      border-radius: 30px;
      background-color: #447fff;
      letter-spacing: 1px;
      text-align: center;
      font-size: 16px;
      margin-right: 0; }
    .home-container .steps .with-logo {
      background-image: url("../../assets/img/heartbeat.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% auto; }
  .home-container__offer .img-wrapper {
    height: 64px;
    margin-bottom: 32px; }
    .home-container__offer .img-wrapper img {
      height: 100%; }
  .home-container--office {
    background-color: white; }
  @media only screen and (max-width: 465px) {
    .home-container .stats-info {
      padding: 13px 50px 13px 50px; }
    .home-container .hero .btn {
      width: 60%; }
    .home-container .hero .row {
      display: block; }
    .home-container .hero .learn-more-container {
      text-align: center;
      margin-bottom: 30px; }
      .home-container .hero .learn-more-container h2 {
        font-size: 20px;
        font-weight: bold;
        text-align: center; }
      .home-container .hero .learn-more-container .lead {
        font-size: 14px;
        text-align: center; }
      .home-container .hero .learn-more-container .btn {
        width: 30%;
        height: 50%;
        font-size: 14px;
        padding: 5px 0;
        display: inline-block; }
    .home-container .hero .logo-mobile-only {
      position: absolute;
      top: 155px;
      right: 35px;
      display: block; }
    .home-container .hero .with-logo {
      background-image: none !important; }
    .home-container .hero .hero-header {
      word-spacing: 9999999px; } }
  @media only screen and (max-width: 321px) {
    .home-container .hero .row {
      display: block; }
    .home-container .hero .learn-more-container {
      text-align: center;
      margin-bottom: 30px; }
      .home-container .hero .learn-more-container h2 {
        font-size: 20px;
        font-weight: bold;
        text-align: center; }
      .home-container .hero .learn-more-container .lead {
        font-size: 14px;
        text-align: center; }
      .home-container .hero .learn-more-container .btn {
        width: 30%;
        height: 50%;
        font-size: 14px;
        padding: 5px 0;
        display: inline-block; }
    .home-container .hero .logo-mobile-only {
      position: absolute;
      top: 170px;
      right: -60px;
      display: block; } }

.learn-more-dialog {
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.6); }
  .learn-more-dialog .MuiDialog-paperWidthSm-9 {
    max-height: unset; }
  .learn-more-dialog .MuiDialog-container-4 {
    height: unset; }
  .learn-more-dialog .MuiDialog-paper-5 {
    margin-top: 28px; }
  .learn-more-dialog .jss6 {
    max-height: unset; }
  .learn-more-dialog .jss4 {
    height: unset; }
  .learn-more-dialog .jss5 {
    margin-top: 28px; }

.alert-danger, .alert-warning {
  color: #721c24 !important;
  background-color: #f8d7da !important;
  border-color: #f5c6cb !important; }

.btn-bar {
  margin-top: 1em;
  text-align: right; }
  .btn-bar button {
    padding-left: 2em;
    padding-right: 2em; }

.react-datepicker-wrapper {
  width: 100%; }

.react-datepicker__input-container {
  width: 100%; }
  .react-datepicker__input-container input {
    width: 100%;
    color: #495057; }

.price {
  margin-top: 0;
  padding-top: 105px;
  padding-bottom: 90px; }
  .price__content__text {
    color: #6d7a8c;
    line-height: 1.5;
    font-size: 1.125rem; }

.post-list__post-item__title {
  padding-top: 45px;
  padding-bottom: 45px; }
  .post-list__post-item__title .mbr-section-title {
    font-size: 1.6rem;
    font-weight: 700; }

.post-list__post-item__content-item {
  padding-top: 45px; }
  .post-list__post-item__content-item .mbr-section-title {
    font-size: 1.125rem;
    width: 100%; }
  .post-list__post-item__content-item .mbr-text {
    font-size: .9rem;
    font-weight: 400; }
  .post-list__post-item__content-item .buttons-wrap {
    text-align: center; }
    .post-list__post-item__content-item .buttons-wrap button {
      padding: 10px 15px;
      font-weight: 600; }

.login__submit-button:disabled {
  background-color: #0654f8 !important;
  border-color: #0654f8 !important;
  padding-top: 20px;
  padding-bottom: 20px; }

.login__links__item {
  font-size: 1rem; }

.text-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 100px;
  /* Tablets (landscape) */ }
  .text-container h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #85BEF6;
    text-align: center;
    color: white;
    min-height: 150px;
    margin-bottom: 20px; }
  .text-container .text {
    padding: 20px 75px;
    text-align: center;
    font-size: 20px;
    min-height: 250px; }
  .text-container .contact-container {
    padding: 20px 20px;
    text-align: center;
    background: #F8FAFB;
    width: 100%;
    min-height: 200px; }
    .text-container .contact-container h4 {
      margin-bottom: 15px; }
  @media only screen and (max-width: 465px) {
    .text-container {
      margin: 0; }
      .text-container .text {
        padding: 0; } }
  @media only screen and (max-width: 321px) {
    .text-container {
      margin: 0; }
      .text-container .text {
        padding: 0; } }

.about-us {
  padding-top: 60px;
  padding-bottom: 30px; }
  .about-us__contents {
    padding: 3rem; }
    .about-us__contents .contents {
      margin-bottom: 40px;
      padding-right: 50px; }
    .about-us__contents .mbr-subtitle {
      font-size: 13px;
      line-height: 24px;
      letter-spacing: 2px;
      color: #4f87fb;
      margin-bottom: 10px;
      font-weight: 800; }
    .about-us__contents .mbr-section-title {
      margin-bottom: 10px;
      color: #130947;
      line-height: 48px;
      letter-spacing: -.5px;
      font-weight: 700;
      font-size: 1.6rem; }
    .about-us__contents__sub-text {
      font-size: 20px;
      line-height: 1.5;
      color: #130947;
      letter-spacing: -.5px;
      margin-top: 40px !important; }
    .about-us__contents__text {
      font-size: 16px;
      line-height: 1.5;
      margin-top: 10px;
      color: #6d7a8c; }
  .about-us__photo__flag {
    position: absolute;
    z-index: 10;
    left: -22px;
    top: 50%;
    transform: translateY(-50%); }
    .about-us__photo__flag .widget-icon {
      display: inline-block;
      border-radius: 100%;
      padding: 31px;
      background-color: #fff;
      font-size: 2.45rem;
      color: #4f87fb;
      margin-right: 15px;
      z-index: 10; }
  .about-us__photo__outer {
    padding: 100px;
    left: -80px;
    border-radius: 100%;
    border: 3px dashed #e3e3e3;
    position: absolute;
    z-index: 7;
    top: 50%;
    transform: translateY(-50%); }
  .about-us__photo__inner {
    border-radius: 100%;
    border: 3px dashed #e3e3e3;
    position: absolute;
    z-index: 7;
    top: 50%;
    transform: translateY(-50%);
    padding: 60px;
    left: -50px; }
  .about-us__photo__pad img {
    max-width: 100%;
    max-height: 800px;
    z-index: 9;
    position: relative; }
  .about-us__icon {
    width: 39px; }

.faq-container {
  height: 100%;
  margin: 0 100px;
  /* Tablets (landscape) */ }
  .faq-container h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #85BEF6;
    text-align: center;
    color: white;
    min-height: 100px;
    margin-bottom: 20px; }
  .faq-container .content-container .item .title {
    font-size: 16px;
    background: #FBF8F9;
    padding: 8px 0;
    font-weight: bold; }
    .faq-container .content-container .item .title .question {
      color: red;
      margin-right: 5px; }
  .faq-container .content-container .item .text {
    font-size: 16px; }
    .faq-container .content-container .item .text .answer {
      color: #4788F4;
      margin-right: 5px; }
  @media only screen and (max-width: 465px) {
    .faq-container {
      margin: 0 2px; } }
  @media only screen and (max-width: 321px) {
    .faq-container {
      margin: 0 2px; } }

.faq {
  padding-top: 90px;
  padding-bottom: 75px;
  position: relative;
  background-color: #fff; }
  .faq__title div {
    margin-bottom: 70px; }
  .faq__title__main {
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 2px;
    color: #4f87fb;
    font-weight: 800; }
  .faq__title__sub {
    line-height: 48px;
    letter-spacing: -.5px;
    color: #130947;
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: .5rem; }
    .faq__title__sub span {
      font-weight: 900; }
  .faq__text {
    font-size: 1rem; }
    .faq__text__item {
      border: none;
      border-bottom: 2px solid #efefef !important; }
      .faq__text__item .card-header {
        background-color: initial;
        border: 0;
        padding: 0; }
        .faq__text__item .card-header a {
          color: #000 !important;
          margin-bottom: 0;
          display: flex;
          width: 100%;
          justify-content: space-between;
          padding-right: 3rem !important;
          text-decoration: none; }
          .faq__text__item .card-header a h4 {
            padding: 1.2rem 0;
            margin-bottom: 0;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: -.5px;
            color: #28303f;
            font-size: 1.125rem; }
      .faq__text__item .toggle-icon {
        line-height: 1.6 !important;
        right: 7px;
        position: absolute;
        top: 36px;
        transform: none;
        display: inline-block;
        height: 2px;
        margin-left: 0;
        width: 12px;
        transition: all .3s;
        padding: 0 !important;
        font-size: 1.4rem !important;
        color: #4f87fb; }
        .faq__text__item .toggle-icon::before {
          height: 12px;
          margin-left: -1px !important;
          width: 2px;
          margin-top: -6px;
          background-color: currentColor;
          border: 0;
          background: currentColor;
          box-sizing: border-box;
          content: "";
          display: block;
          left: 50%;
          position: absolute;
          top: 50%;
          transform: scaleY(1) !important;
          transition: all .3s; }
        .faq__text__item .toggle-icon::after {
          height: 2px;
          width: 12px;
          margin-left: -6px;
          margin-top: -1px;
          background: currentColor;
          border: 0;
          box-sizing: border-box;
          content: "";
          display: block;
          left: 50%;
          position: absolute;
          top: 50%; }
      .faq__text__item .panel-body {
        padding: 14px 20px; }
        .faq__text__item .panel-body .panel-text {
          font-size: 16px;
          color: #6d7a8c;
          line-height: 1.5; }

.blog-container {
  height: 100%;
  margin: 0 100px;
  /* Tablets (landscape) */ }
  .blog-container h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #85BEF6;
    text-align: center;
    color: white;
    min-height: 100px;
    margin-bottom: 20px;
    font-size: 25px; }
  .blog-container .content-container .item .title {
    font-size: 16px;
    background: #FBF8F9;
    padding: 8px 0;
    font-weight: bold; }
    .blog-container .content-container .item .title .question {
      color: red;
      margin-right: 5px; }
  .blog-container .content-container .item .text {
    font-size: 16px; }
    .blog-container .content-container .item .text .answer {
      color: #4788F4;
      margin-right: 5px; }
  .blog-container .contact-container {
    padding-top: 20px;
    background: #F8FAFB;
    min-height: 200px; }
    .blog-container .contact-container .text {
      font-size: 16px; }
  @media only screen and (max-width: 465px) {
    .blog-container {
      margin: 0 2px; } }
  @media only screen and (max-width: 321px) {
    .blog-container {
      margin: 0 2px; } }

.blog-list__item {
  padding-top: 45px; }
  .blog-list__item__title {
    text-align: left;
    width: 100%; }
  .blog-list__item__content {
    font-size: .9rem;
    font-weight: 400; }

.main-banner {
  display: flex;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  background-color: #eee; }
  .main-banner .row {
    text-align: center; }
  .main-banner .mbr-overlay {
    min-height: 100vh;
    bottom: 0;
    left: 0;
    opacity: .5;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0; }
  .main-banner .mbr-section-title {
    font-weight: 900;
    font-size: 3.4375rem; }
    .main-banner .mbr-section-title .accent-word {
      color: #4f87fb; }
  @media (max-width: 768px) {
    .main-banner .mbr-section-title {
      font-size: calc(1.85313rem + 1.58438*(100vw - 20rem)/28); } }
  .main-banner .mbr-section-subtitle {
    color: #4f87fb;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 2px;
    font-size: .9rem; }
  .main-banner__buttons {
    display: flex;
    flex-wrap: wrap;
    margin-top: 47px; }
    .main-banner__buttons__learn-more {
      line-height: 26px;
      letter-spacing: 1px;
      font-weight: 700;
      font-size: 13px;
      border-radius: 4px;
      margin: 0.4rem 0.6rem 0.4rem 0 !important;
      padding: 15px 40px;
      align-items: center;
      display: inline-flex;
      background-color: #4f87fb !important;
      border-color: #4f87fb !important;
      color: #fff !important;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.18);
      transition: all .3s ease-in-out; }
      .main-banner__buttons__learn-more:hover {
        box-shadow: none !important;
        background-color: #130947 !important;
        border-color: #130947 !important;
        color: #fff !important; }
    .main-banner__buttons__heart span {
      line-height: 1;
      display: inline-block; }
    .main-banner__buttons__get-refill {
      font-size: .9rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #130947;
      font-weight: 700;
      cursor: pointer; }

@media (max-width: 768px) {
  .main-banner__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 57px; } }

.footer1 {
  border: none;
  padding-top: 75px;
  padding-bottom: 75px;
  background-color: #25303e; }
  .footer1__col {
    margin-bottom: 32px; }
  .footer1 .container .list .list-item .content h4 a {
    font-size: 13px;
    color: #4f87fb; }
    .footer1 .container .list .list-item .content h4 a:hover {
      text-decoration: none; }
  .footer1 .container .line {
    width: 50px;
    height: 2px;
    display: inline-block;
    background: #4f87fb;
    transition: background .3s;
    margin-bottom: 2rem; }
  .footer1 .container .media-container-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: start; }
    @media (min-width: 992px) {
      .footer1 .container .media-container-row {
        flex-wrap: nowrap; } }
  .footer1 .container .widget-title {
    font-weight: 600; }
    .footer1 .container .widget-title .display-7 {
      font-family: Muli,sans-serif;
      font-size: 1.125rem;
      line-height: 27px;
      letter-spacing: 0.5px;
      font-weight: 600; }
  .footer1 .container .align-left {
    text-align: left; }
  .footer1 .container .item {
    display: flex;
    align-items: center;
    padding-bottom: .5rem;
    margin-bottom: .6rem; }
    .footer1 .container .item .card-img {
      width: auto;
      background-color: #4f87fb;
      border-radius: 100%; }
      .footer1 .container .item .card-img .img1 {
        display: block;
        padding: 6px;
        font-size: 13px;
        color: #fff;
        background-color: #4f87fb;
        border-radius: 50%;
        line-height: 1.3; }
        .footer1 .container .item .card-img .img1 .fa-map-marker-alt {
          font-size: 13px;
          text-align: center;
          width: 13px; }
    .footer1 .container .item .card-box .item-title {
      font-size: 16px;
      line-height: 1.5;
      font-weight: 300; }
    .footer1 .container .item h4 {
      padding-left: 10px;
      margin: 0; }
  .footer1 .container .link {
    display: flex;
    text-align: left; }
    .footer1 .container .link .card-img {
      width: auto; }
    .footer1 .container .link .card-box .link-title {
      padding-left: 10px;
      padding-bottom: .5rem;
      margin-bottom: .6rem; }
      .footer1 .container .link .card-box .link-title a {
        color: #4f87fb; }
        .footer1 .container .link .card-box .link-title a:hover {
          text-decoration: none;
          color: #4f87fb; }
  .footer1 .container .display-4 {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300; }
  .footer1 .container .img2 {
    color: #fff;
    font-size: 10px; }
  .footer1 .tips {
    height: 70px;
    width: 70px;
    margin: 4px;
    object-fit: cover; }
  .footer1 .box-list {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
    font-weight: 300; }
  .footer1 .box-list.display-4 {
    line-height: 24px; }
  .footer1 .box-list li {
    padding: 10px 0;
    color: #fff; }
  .footer1 .box-list li:first-child {
    padding-top: 0; }
  .footer1 .box-list li:last-child {
    border-bottom: none; }

.footer2 {
  background: #25303E;
  border: none;
  padding-top: 30px;
  padding-bottom: 30px; }
  .footer2 .links {
    text-align: center;
    color: #fff; }
  .footer2 p {
    margin: 0; }
  .footer2 .display-4, .footer2 .display-4 > .mbr-iconfont {
    font-size: .9rem; }
  .footer2 a {
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
    color: #fff;
    background-color: initial; }
  .footer2 a:hover {
    color: #4f87fb !important;
    transition: all .3s; }

.StripeElement {
  margin-bottom: 20px; }

.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 gray;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease; }

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #17a2b8; }

.StripeElement--invalid {
  border-color: #fa755a; }

.prescription-cost {
  font-size: 36px;
  flex-basis: 33%;
  text-align: center; }
  .prescription-cost .badge {
    padding: 12px 24px;
    font-weight: 600;
    margin: 10px; }

.prescription-form .form .sub-title {
  flex-basis: 66%; }

.prescription-form .form .custom-control-label {
  padding-top: 0.25rem;
  padding-left: 0.5rem; }
  .prescription-form .form .custom-control-label::before, .prescription-form .form .custom-control-label::after {
    width: 1.5rem;
    height: 1.5rem; }

.prescription-form .mb-6-5 {
  margin-bottom: 6.5rem; }

.payment-back-btn-container {
  float: right;
  width: 60%;
  text-align: center;
  padding: 20px 0px; }
  .payment-back-btn-container button {
    width: 100%; }

.cost-description {
  padding: 20px 0; }

#checkout-dialog .title {
  padding: 12px 16px;
  background-color: #dfdfdf; }
  #checkout-dialog .title h2 span {
    font-size: 18px;
    color: #343a40; }

#checkout-dialog .content {
  padding: 24px;
  background-color: #f2f2f2; }
  #checkout-dialog .content > div {
    margin: 0px; }

#checkout-dialog .action {
  margin: 0px;
  padding: 8px 4px;
  background-color: #f5f5f5; }

#edit-sleep-info-page .custom-control-label {
  cursor: pointer; }

#edit-sleep-info-page .form-control {
  width: auto;
  display: inline-block; }

#edit-sleep-info-page input.form-control {
  width: 80px; }

#edit-sleep-info-page .custom-range {
  width: 300px;
  height: auto; }

#edit-sleep-info-page .react-datepicker-wrapper input {
  width: 200px; }

#edit-sleep-info-page .react-datepicker__time-container {
  width: auto; }
  #edit-sleep-info-page .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: auto; }

#last-login-dialog .field-name {
  display: inline-block;
  width: 120px;
  color: #343a40;
  font-weight: 500; }

#last-login-dialog h2 {
  display: flex;
  justify-content: space-between; }
  #last-login-dialog h2 .count-down {
    display: inline-block; }

/*
* App
*/
main {
  margin-left: auto;
  padding-top: 40px;
  /* space for fixed navbar */ }
  main.logged-in {
    padding-top: 0px;
    padding-bottom: 20px; }
  @media only screen and (max-width: 465px) {
    main {
      margin-top: 35px; } }

body {
  font-size: .875rem; }

.btn-xs {
  font-size: 11px;
  font-weight: 600;
  padding: 3px 6px; }

.description {
  font-size: 14px;
  color: #17a2b8; }

label.title {
  font-weight: 700;
  font-size: 15px;
  color: #606060; }

.fa {
  font-size: 20px;
  font-family: 'FontAwesome'; }

.fas {
  font-size: 20px;
  font-family: "Font Awesome 5 Free"; }

/*
* Content
*/
.form-control-dark {
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1); }

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25); }

.custom-checkbox {
  cursor: pointer; }
  .custom-checkbox input, .custom-checkbox label {
    cursor: pointer; }

table tr th {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #343a40 !important; }

.btn.disabled {
  cursor: not-allowed; }

strong {
  color: #606060;
  font-size: 15px; }

.react-datepicker-wrapper input {
  height: 38px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 10px; }

.react-datepicker-wrapper .react-datepicker__close-icon::after {
  top: 5px;
  font-size: 20px;
  font-weight: 700;
  padding: 0px;
  width: 24px;
  height: 24px;
  background-color: #dfdfdf;
  color: #343a40; }

.custom-file input, .custom-file label {
  width: 300px; }

i {
  padding-top: 2px; }

table tbody tr {
  background-color: rgba(242, 245, 245, 0.8);
  box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122); }

.link-button {
  border: none;
  background-color: transparent;
  font-style: italic;
  color: blue; }

.link-button:hover {
  text-decoration: underline; }
